import React, { useState, useEffect, useContext } from 'react';
import PropTypes from "prop-types";
import moment from 'moment'
import { Paper, TextField, MenuItem} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import OtherDropDown from '../../../components/OtherDropDown';
import AppContext from '../../../context/app'

CreateCommunity.propTypes = {
    assessmentModel: PropTypes.object,
    assessment: PropTypes.object,
    institution: PropTypes.object,
    building: PropTypes.object,
    onChange: PropTypes.func
};

export default function CreateCommunity(props) {
  const context = useContext(AppContext)

  useEffect( async () => {
    props.assessment.threatMadeDate = moment().format()
    props.assessment.threatMadeDateIsValid = true   
    props.onChange(props.assessment)
  },[])

  return (
        <div>
            <Paper className="paper">
                <h2>Incident or Behavior of Concern</h2>
                <div className="flex-row ">
                  <div className="flex-col assessedFirstName-container">
                    <TextField label={`First Name of ${ props.assessmentModel?.config.assessedLabel || 'Subject of Concern'}`} type="text" maxLength="25" value={props.assessment.assessedFirstName || ''} required onChange={(e) => { props.assessment.assessedFirstName = e.target.value; props.onChange(props.assessment)}}/>
                  </div>
                  <div className="flex-col assessedLastName-container">
                    <TextField label={`Last Name of ${ props.assessmentModel?.config.assessedLabel || 'Subject of Concern'}`} type="text" maxLength="25" value={props.assessment.assessedLastName || ''} required onChange={(e) => { props.assessment.assessedLastName = e.target.value; props.onChange(props.assessment)}}/>
                  </div>
                </div>

                <div className="flex-row ">
                  <div className="flex-col gender">
                    <TextField select label="Identification" required value={props.assessment.gender || ''} onChange={(e) => { props.assessment.gender = e.target.value; props.onChange(props.assessment)}}>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Neutral">Neutral</MenuItem>
                    </TextField>
                  </div>

                  <div className="flex-col race">
                    <TextField select label="Race" required value={props.assessment.race || ''} onChange={(e) => { props.assessment.race = e.target.value; props.onChange(props.assessment)}}>
                      <MenuItem value="African American">African American</MenuItem>
                      <MenuItem value="American Indian">American Indian</MenuItem>
                      <MenuItem value="Asian">Asian</MenuItem>
                      <MenuItem value="Latin American">Latin American</MenuItem>
                      <MenuItem value="White">White</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </TextField>
                  </div>


                  <div className="flex-col dateOfBirth">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker disableFuture={true} openTo='year' fullWidth renderInput={(props) => <TextField required {...props} />} label="Date Of Birth" value={props.assessment.dateOfBirth} required onError={(e) => {props.assessment.dateOfBirthIsValid = e === null; props.onChange(props.assessment)}} onChange={(e) => { if (!e) { return }; props.assessment.dateOfBirthIsValid = e.isValid();  props.assessment.dateOfBirth = e; props.onChange(props.assessment)}}/>
                    </LocalizationProvider>
                  </div>

                  <div className="flex-col">
                    <TextField label="Case ID" type="text" maxLength="30" value={props.assessment.data?.caseId || ''} onChange={(e) => { props.assessment.data.caseId = e.target.value; props.onChange(props.assessment)}}/>
                  </div>
                </div>
              </Paper>
              
              <Paper className="paper" >
                <h2>Reported By</h2>
                <div className="flex-row">
                    <div className="flex-col reason">
                      <OtherDropDown fullWidth value={props.assessment.reason}  label="Affiliation of person reporting threat" required onChange={(e) => { props.assessment.reason = e.target.value; props.onChange(props.assessment)}} >
                          <MenuItem value="Anonymous Communication">Anonymous Communication</MenuItem>
                          <MenuItem value="Student">Student</MenuItem>
                          <MenuItem value="Faculty/Staff">Faculty/Staff</MenuItem>
                          <MenuItem value="Parent/Family">Parent/Family</MenuItem>
                      </OtherDropDown>
                    </div>
                </div>
              </Paper>

              <Paper className="paper" >
                <h2>Summary</h2>
                <div>
                  <TextField label="Summary of the Incident or Threat" multiline maxRows={5} type="text"  inputProps={{ maxLength: 8000}}  maxLength="8000" value={props.assessment.comments || ''} required onChange={(e) => { props.assessment.comments = e.target.value; props.onChange(props.assessment)}}/>
                </div>
              </Paper>
    </div>
  )
}
