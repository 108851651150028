import React, { useEffect, useContext, useState } from 'react';

import CommunityFollowUpItem from './CommunityFollowUpItem'

import AppContext from '../../../context/app'
import formService from '../../../services/assessment/form/form';

const formId = 7

export default function Form(props) {
    const context = useContext(AppContext)
    const [forms, setForms] = useState([])
    
    useEffect( async () => {
        let loadedforms = await new formService().getForms(props.threatAssessmentId,formId)
        setForms(loadedforms)
    },[])

    return (
    <div id="assessmentForms">
        <CommunityFollowUpItem {...props} threatAssessmentId={props.threatAssessmentId} />

        { forms.map( (form, index) => {
            return (
            <div key={index} className="flex-row">
                <CommunityFollowUpItem {...props} threatAssessmentId={props.threatAssessmentId} formAnswerId={form.formAnswerId} editForm={form} />
            </div>
            )
        })}
    </div>
    )
}